<template>
  <div>
    <ul>
      <li v-for="(item, index) in list" :key="index" @click.stop="selectProvider(item)">
        <div class="card" v-show="!isShowMoreList || index < 2">
          <div>
            <span class="proType" v-if="item.biaoQian">
              <!-- {{
                item.biaoQian === 'zhunShiDa'
                  ? '大票快递'
                  : item.biaoQian === 'jiaGeZuiDi'
                  ? '大票特惠'
                  : '大票特快'
              }} -->
              {{ showPdTagFun(item.biaoQian) }}
            </span>
            <span class="ml20" v-if="item.biaoQian">|</span>
            <span class="ml20">{{ item.truckTypeName }}</span>
          </div>
          <div class="context clearfix">
            <div class="fleft arrive pt10 pb10">预计{{ arriveTime(item.lineHour) }}到达</div>
            <div class="fright price">
              <span class="fBig">{{ item.discountAmount | fixed2 }}</span
              ><span class="more">起</span>
            </div>
          </div>
          <div class="discount clearfix" v-if="item.discountValue">
            <div class="fleft dis">
              <van-tag plain type="danger" class="marR10"
                >{{ item.couponTypeName }}|{{ item.discountValue }}折</van-tag
              >
              <van-tag plain type="danger bg_red"
                >优惠{{ item.feiYongHeJi - item.discountAmount }}元</van-tag
              >
            </div>
            <div class="fright price">
              <span class="del">{{ item.feiYongHeJi | fixed2 }}</span
              ><span class="more">起</span>
            </div>
          </div>
          <ul class="mt20 padlr20">
            <li class="mt10" v-if="item.biaoQian && item.biaoQian == 'zhunShiDa'">
              <van-tag plain type="primary" class="marR10">慢必赔</van-tag>
              <van-tag plain type="primary" class="marR10">贵比赔</van-tag>
              <van-tag plain type="primary" class="marR10">VIP客服</van-tag>
              <van-tag plain type="primary" class="marR10">优先派送</van-tag>
            </li>
            <li class="mt10" v-else>
              <van-tag plain type="primary" class="marR10">贵必赔</van-tag>
              <van-tag plain type="primary" class="marR10">慢必赔</van-tag>
              <van-tag plain type="primary" class="marR10">损必赔</van-tag>
            </li>
          </ul>
          <!-- <div class="mt20 padlr20" @click.stop="handleToggle(item)">
            <div class="pb10">
              <span class="cor666">运输服务｜</span
              ><span class="cor888">{{ item.zhuGanSupplierName }}</span
              ><van-icon class="ml10" name="arrow-down" />
            </div>
          </div> -->
          <!-- <ul class="bg" v-show="item.isShow"> -->
          <!-- <li v-if="item.tiHuoSupplierName">
              <span class="cor666">提货服务｜</span
              ><span class="cor888">{{ item.tiHuoSupplierName }}</span>
            </li>
            <li v-if="item.peiSongSupplierName">
              <span class="cor666">送货服务｜</span
              ><span class="cor888">{{ item.peiSongSupplierName }}</span>
            </li>
            <li class="mt10" v-if="item.biaoQian && item.biaoQian == 'zhunShiDa'">
              <van-tag plain type="primary" class="marR10">慢必赔</van-tag>
              <van-tag plain type="primary" class="marR10">贵比赔</van-tag>
              <van-tag plain type="primary" class="marR10">VIP客服</van-tag>
              <van-tag plain type="primary" class="marR10">优先派送</van-tag>
            </li>
            <li class="mt10" v-else>
              <van-tag plain type="primary" class="marR10">贵必赔</van-tag>
              <van-tag plain type="primary" class="marR10">慢必赔</van-tag>
              <van-tag plain type="primary" class="marR10">损必赔</van-tag>
            </li> -->
          <!-- </ul> -->
          <van-row class="padlr20 mt10">
            <!-- <van-col class="cor888 marR30"
              ><van-icon
                name="gold-coin-o"
                color="#888"
                class="marR10"
              />价格pk</van-col
            > -->
            <van-col class="cor888 marR30" @click.stop="() => jumpToPage('3')"
              ><van-icon name="logistics" color="#888" class="marR10" />收货标准</van-col
            >
            <van-col
              class="cor888 marR30"
              v-if="item.biaoQian && item.biaoQian == 'zhunShiDa'"
              @click.stop="showStarDialog"
              ><van-icon name="star-o" color="#888" class="marR10" />标示说明</van-col
            >
          </van-row>
        </div>
      </li>
    </ul>
    <div @click="showMore" class="tips" v-if="list.length > 2">
      <p v-if="isShowMoreList">-点击查看更多（{{ list.length - 2 }}）-</p>
      <p v-else>-点击收起({{ list.length - 2 }})-</p>
    </div>
    <van-dialog v-model="showDialog" title="大票快递产品说明">
      <div class="plr20 fz24">
        <p>从您下单至派送全流程各环节提供以下尊享服务：</p>
        <p>
          1、<span class="fb">极速提货</span
          >：17点前完成下单，两小时内上门提货（特殊区域稍作加时），17点后订单次日上午提货；
        </p>
        <p>
          2、<span class="fb">超时赔付</span>：超出时效承诺，运费减半（不可抗力及客户原因除外）；
        </p>
        <p>3、<span class="fb">专属服务</span>：一对一VIP客户服务，预约派送时间，末端优先派送。</p>
        <p>4、<span class="fb">免费投保</span>：赠送保额12500元的货物运输保险。</p>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import moment from 'moment'
import { Dialog } from 'vant'
import { filterZengZhi, pdTagFun } from '@/assets/js/order'
export default {
  name: 'proCard',
  props: {
    proTabTime: {
      type: Number
    },
    times: {
      type: String,
      required: true
    },
    newStepArr: {
      type: Object,
      required: true
    },
    isBatch: {
      type: Boolean
    },
    faIdx: {
      type: Number
    }
  },
  filters: {
    fixed2(val) {
      if (val) {
        return '￥' + (+val).toFixed(2)
      } else {
        return '￥0.00'
      }
    }
  },
  watch: {
    proTabTime(newVal) {
      this.list = this.$store.state.providerAll
    }
  },
  data() {
    return {
      list: this.$store.state.providerAll,
      isShowMoreList: this.$store.state.providerAll.length,
      showDialog: false
    }
  },
  created() {
    this.initData()
  },
  methods: {
    //展示产品标识
    showPdTagFun(biaoQian) {
      return pdTagFun(biaoQian)
    },
    arriveTime(hours) {
      // 发货当天时间不算，时效从第二天时间算起
      let startTime = moment(this.times)
        .startOf('day')
        .add(1, 'days')
        .format('YYYY-MM-DD HH:mm:ss')
      //第二天零点+时效
      let endTime = new moment(startTime).add(hours, 'hours').format('YYYY-MM-DD HH:mm:ss')
      let timeDiff = moment(endTime).diff(moment().format('YYYY-MM-DD HH:mm:ss'), 'minute') //计算两个时间差,单位是分
      // 小时
      let timeH = Math.floor(timeDiff / 60)
      let dayStr = ''
      if (timeH > 0 && timeH < 24) {
        dayStr = '当天'
      } else if (timeH > 24 && timeH < 48) {
        dayStr = '明天'
      } else if (timeH > 48) {
        let monthday = moment(endTime)
          .format('YYYY-MM-DD')
          .split('-')
        dayStr = monthday[1] + '月' + monthday[2] + '日'
      }
      return dayStr
    },
    initData() {
      this.list = this.list.map(item => {
        const obj = {}
        obj.isShow = false
        return Object.assign({}, item, obj)
      })
    },
    handleToggle(item) {
      item.isShow = !item.isShow
    },
    showMore() {
      this.isShowMoreList = !this.isShowMoreList
    },
    selectProvider(item) {
      if (item.productType == 'XP' && this.newStepArr.receiptTypeName == '纸质回单 | 普通') {
        this.$dialog
          .confirm({
            title: '温馨提示',
            message: '小票零担产品暂不享有纸质回单服务优惠',
            cancelButtonText: '选择其他产品',
            confirmButtonText: '继续下单',
            confirmButtonColor: '#007EC5'
          })
          .then(() => {
            // this.newStepArr.receiptTypeName = '无需回单'
            // this.newStepArr.receiptType = 'ZL1605215'
            this.savePriver(item)
          })
          .catch(() => {
            return
          })
      } else {
        this.savePriver(item)
      }
    },
    savePriver(item) {
      this.newStepArr = {
        ...this.newStepArr,
        ...item
      }
      filterZengZhi(this, this.callbackZengZhi)
    },
    jumpNext() {
      this.$router.push({
        name: 'confirmOrder',
        params: {
          oneDatas: this.newStepArr,
          isBatch: this.isBatch || false,
          faIdx: this.faIdx
        }
      })
    },
    callbackZengZhi(wrongArr) {
      let self = this
      if (!wrongArr.length) {
        self.jumpNext()
        return
      }
      let tipsTxt = '此线路不存在 ' + wrongArr.join('、') + '服务'
      Dialog.alert({
        title: '温馨提示',
        message: tipsTxt
      }).then(() => {
        self.jumpNext()
      })
    },
    showStarDialog() {
      this.showDialog = true
    },
    jumpToPage(type) {
      this.$router.push({
        name: 'm_orderagreement',
        meta: {
          title: type === '1' ? '运输条款' : type === '2' ? '隐私政策' : '收货标准'
        },
        params: {
          tabType: type || '1',
          path: 'lineGoods',
          allDatas: this.newStepArr
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.fleft {
  float: left;
  margin: 0;
}
.fright {
  float: right;
  margin: 0;
}
.clearfix {
  overflow: hidden;
}
.marR10 {
  margin-right: 10px;
}
.marR30 {
  margin-right: 30px;
}
.mt10 {
  margin-top: 10px;
}
.cor888 {
  color: #888;
}
.cor666 {
  color: #ccc;
}
.padlr20 {
  padding: 0 20px;
}
.padb10 {
  padding-bottom: 10px;
}
.bg {
  background-color: #f2f2f2;
  padding: 10px 20px;
  li {
    line-height: 40px;
  }
}
.tips {
  text-align: center;
}
.card {
  border-radius: 12px;
  background-color: #fff;
  margin-top: 20px;
  -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  padding-bottom: 30px;
  .proType {
    min-width: 120px;
    padding: 5px 20px;
    background: linear-gradient(#ffebd0, #ffffff);
    color: #b93a13;
    border-radius: 0 0 12px 0;
    text-align: center;
  }
  .context {
    padding: 0 20px;
    .arrive {
      color: #000;
      font-size: 30px;
      font-weight: 600;
      line-height: 60px;
    }
    .price {
      color: #ff6231;
      .fBig {
        font-size: 44px;
      }
      .more {
        font-size: 12px;
      }
    }
  }
  .discount {
    padding: 0 20px;
    .dis {
      font-size: 10px;
    }
    .price {
      color: #acacac;
      .del {
        text-decoration: line-through;
      }
    }
  }
  .bg_red {
    background-color: #ffeeed;
  }
}
</style>
