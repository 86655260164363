<template>
  <div class="pad30">
    <div class="mb30 nobg_box block_base" v-if="isBatch">
      <van-steps :active="1" active-color="#007EC5">
        <van-step>货物信息</van-step>
        <van-step>选择产品</van-step>
        <van-step>信息确认</van-step>
      </van-steps>
    </div>
    <div class="marb20">{{ FromAddress }}-{{ ToAddress }}</div>
    <!-- <van-row class="marb20 flex_end"> -->
    <!-- <van-button
        class="icon_btn"
        type="default"
        size="mini"
        icon="back-top"
        v-for="item in $store.state.tagsDat"
        :key="item.id"
        :class="{ checked: $store.state.selectedTags === item.id }"
        :data-id="item.id"
        @click="changeProTab"
      >
        {{ item.name }}
      </van-button> -->
    <!-- <van-button class="icon_btn" type="default" size="mini" icon="back-top">价格</van-button>
          <van-button class="icon_btn" type="default" size="mini" icon="back-top">时效</van-button> -->
    <!-- </van-row> -->
    <ProCard
      :times="newStepArr.sendersDate || ''"
      v-if="$store.state.providerAll.length"
      :newStepArr="newStepArr"
      :isBatch="isBatch"
      :faIdx="faIdx"
      :proTabTime="proTabTime"
    ></ProCard>
  </div>
</template>
<script>
import ProCard from './ProCard.vue'
import { providerInfoAllN } from '@/assets/js/order'
import { scanCallback } from '../../../assets/js/apilist'
export default {
  name: 'lineGoods',
  components: {
    ProCard
  },
  computed: {
    FromAddress() {
      let addr =
        (this.newStepArr.sendersProvinceName || '') +
        '' +
        (this.newStepArr.sendersCityName || '') +
        '' +
        (this.newStepArr.sendersDistrictName || '')
      return addr
    },
    ToAddress() {
      let addr =
        (this.newStepArr.recipientProvinceName || '') +
        '' +
        (this.newStepArr.recipientCityName || '') +
        '' +
        (this.newStepArr.recipientDistrictName || '')
      return addr
    }
  },
  created() {
    let allDatas = this.$route.params
    if (allDatas.oneDatas) {
      this.newStepArr = allDatas.oneDatas
      this.isBatch = allDatas.isBatch || false
      this.faIdx = allDatas.faIdx
    } else {
      if (!this.isBatch) {
        this.$router.push({
          path: '/m_payorder'
        })
      } else {
        this.$router.push({
          path: '/m_batchorder'
        })
      }
    }
    if (!this.$store.state.providerAll.length) {
      providerInfoAllN(this, this.newStepArr)
    }
  },
  data() {
    return {
      newStepArr: {},
      isBatch: false,
      faIdx: -1,
      proTabTime: 0 //用于子组件监听变化
    }
  },
  methods: {
    changeProTab(e) {
      const type = e.currentTarget.getAttribute('data-id')
      this.$store.state.selectedTags = type
      providerInfoAllN(this, this.newStepArr, false, type, this.callbackProvider)
    },
    callbackProvider(res) {
      this.proTabTime = new Date().getTime()
    }
  }
}
</script>
<style scoped>
.pad30 {
  padding: 30px 25px;
}
.marb20 {
  margin-bottom: 20px;
}
.icon_btn {
  /* background: url('/src/assets/images/up-filter.png') no-repeat; */
  height: 55px;
  padding: 10px 15px;
  border-radius: 28px;
}
.icon_btn.checked {
  border-color: #1989fa;
  color: #1989fa;
}
</style>
